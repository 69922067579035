import { Component, Prop, Mixins } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import { EditRequest } from "@/api/info-authcode/request";
import { RegisterRequest } from "@/api/authcode/request";
import InfoAuthcodeEdit from "@/store/info-authcode/edit";
import AuthcodeRegister from "@/store/authcode/register";
import Flash, { ErrorAlert } from "@/store/common/flash";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import WindowSizeChecker from "@/utils/window-size-checker";

@Component({ components: { UlContentHeader, UlBreadcrumbs } })
export default class Edit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  id!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "認証コード";
  headingSub = "Auth code";

  // ------------

  // ------------
  // 変動値
  // ------------
  // ローディングステータス
  isLoading = false;

  // 認証種別（クエリパラメータ）
  authType = "";

  // 認証コード
  authCode = "";

  // パンくず
  get breadCrumbs() {
    if (this.$route.query.fromStampList) {
      return [
        { text: "店舗一覧", disabled: false, to: { name: "stamps" } },
        {
          text: "スタンプ一覧",
          disabled: false,
          to: {
            name: "stamps-list",
            params: { shopId: this.id },
            query: { shopName: this.shopName }
          }
        },
        { text: "編集", disabled: true }
      ];
    } else if(this.$route.query.fromStampQrcode ) {
      return [
        { text: "アピール", disabled: true },
        { text: "スタンプ", disabled: true },
        { text: "店舗一覧", disabled: false, to: { name: "stamps" } },
        { text: "スタンプ一覧", disabled: true },
        { text: "条件設定", disabled: true },
        { text: "QR表示", disabled: true },
        { text: "編集", disabled: true }
      ];
    } else {
      return [
        { text: "設定", disabled: true },
        { text: "認証コード", disabled: true },
        { text: "店舗一覧", disabled: false, to: { name: "auth-code" } },
        { text: "編集", disabled: true }
      ];
    }
  }

  /**
   * 画面に表示する認証コードデータ
   */
  get result() {
    return InfoAuthcodeEdit.getResult;
  }

  /**
   * 幅がSPか否か
   */
  get isPhone() {
    return WindowSizeChecker.isPhone(this);
  }

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  get stampAuthId() {
    if (this.$route.query.stampAuthId) {
      return this.$route.query.stampAuthId as string;
    } else {
      return "";
    }
  }

  /**
   * createdライフサイクルフック（クエリパラメータの取得）
   */
  async created() {
    if (this.$route.query.authType) {
      const authType = this.$route.query.authType;
      this.authType = authType ? (authType as string) : "";
    }

    await this.fetchInfoAuthcode();
    this.authCode = this.result.code;

    if (this.$route.query.ticketEditPage) {
      localStorage.setItem('ticketEditPage', 3);
    }
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await InfoAuthcodeEdit.clearResponse();
    await AuthcodeRegister.clearResponse();
  }

  /**
   * 編集画面の認証コードデータを取得する
   */
  async fetchInfoAuthcode(): Promise<boolean> {
    if (InfoAuthcodeEdit.isSuccess) {
      return true;
    }

    this.isLoading = true;
    const request = this.createEditRequest();
    await InfoAuthcodeEdit.edit(request);
    if (!InfoAuthcodeEdit.isSuccess) {
      await Flash.setErrorNow({
        message: InfoAuthcodeEdit.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
    return InfoAuthcodeEdit.isSuccess;
  }

  /**
   * 認証コードを更新する
   */
  async register(): Promise<boolean> {
    await Flash.clear();
    if (this.isLoading) {
      return true;
    }

    const request = this.createRegisterRequest();
    await AuthcodeRegister.register(request);
    if (AuthcodeRegister.isSuccess) {
      await this.redirectWithSuccessAlert(
        "認証コードを保存しました。",
        "/auth-code"
      );
    } else {
      await Flash.setErrorNow({
        message: AuthcodeRegister.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }

    // チケット画面からの遷移の場合はチケット編集画面3へもどる
    if (localStorage.getItem('ticketEditPage') && localStorage.getItem('ticketEditPage') == 3) {
      this.$router.go(-2);
    }

    return AuthcodeRegister.isSuccess;
  }

  /**
   * dataから認証コード情報詳細取得用のリクエストを作成する
   */
  private createEditRequest(): EditRequest {
    const request = {
      type: Number(this.authType)
    } as EditRequest;
    if (this.authType === "0") {
      request.id = this.stampAuthId;
    } else {
      request.id = this.id;
    }
    return request;
  }

  /**
   * dataから登録更新用のリクエストを作成する
   */
  private createRegisterRequest(): RegisterRequest {
    const request = {
      type: Number(this.authType),
      code: this.authCode
    } as RegisterRequest;
    if (this.authType === "0") {
      request.id = this.stampAuthId;
    } else {
      request.id = this.id;
    }
    return request;
  }
}
